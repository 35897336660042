<template>
  <div :ref="ename" :style="{ width, height }"></div>
</template>
    
    <script>
export default {
  props: {
    data: {
      type: Object,
    },
    ename: {
      type: String,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    onclick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myChart: "",
      list: [],
      option: {
					 tooltip: {
					        trigger: 'item',
					        formatter: "{b} : {c} ({d}%)",
							 textStyle: {
							            fontSize: 8,
							        },
					    },
						grid: {
							top: '10%',
							left: '10%',
							right: '10%',
							bottom: '10%'
						},
					    series: [{
					        name: '注册来源',
					        type: 'pie',
							// roseType : 'area',
					        radius: '70%',
					        center: ['50%', '50%'],
					        clockwise: false,
					        data: [],
					        label: {
					            normal: {
					                textStyle: {
					                    color: '#999',
					                    fontSize: 10,
										 left: 20,
					                }
					            },

					        },
					        labelLine: {
					            normal: {
					                show: false
					            }
					        },
					        itemStyle: {
					            normal: {
					                borderWidth: 4,
					                borderColor: '#ffffff',
					            },
					            emphasis: {
					                borderWidth: 0,
					                shadowBlur: 10,
					                shadowOffsetX: 0,
					                shadowColor: 'rgba(0, 0, 0, 0.5)'
					            }
					        }
					    }],
					    color: ['#37a2da','#32c5e9','#9fe6b8','#ffdb5c','#ff9f7f','#fb7293','#e7bcf3','#8378ea'],
					    backgroundColor: '#fff'
				}
    };
  },
  computed: {
    full() {
      return this.$store.state.menuShow;
    },
  },
  watch: {
    full(newval) {
      this.myChart.resize();
    },
    data: {
      handler(newVal) {
        if (newVal && this.myChart != "") {
          this.list = newVal;
          this.updateOption();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getEchartData();
    window.addEventListener("resize", this.myChart.resize);
  },
  beforeDestroy() {
    // 移除窗口改变监听

    this.onclick && this.myChart.off("click", this.getClickInfo);
    window.removeEventListener("resize", this.myChart.resize);
  },
  methods: {
    getClickInfo(e) {
      console.log(e);

      this.$emit("click", e);
    },
    updateOption() {
    
      this.option.series[0].data = this.list.Data;
      this.myChart.setOption(this.option);
    },
    getEchartData() {
      this.option.series[0].data = this.list.Data;
      
      const chart = this.$refs[this.ename];
      if (chart) {
        this.myChart = this.$echarts.init(chart);
        this.myChart.setOption(this.option);
        this.onclick && this.myChart.on("click", this.getClickInfo);
      }
    },
  },
};
</script>
    
    <style lang="scss" scoped>
</style>