<template>
  <div class="container">
    <content-title :nav="nav"> </content-title>
    <!-- 用户总数昨日新增用户 -->
    <div class="count-wrap">
      <div
        class="count-item"
        v-for="(v, i) in countList"
        :key="i"
        :style="{ background: v.color }"
      >
        <div class="count-l">
          <div class="name">{{ v.name }}</div>
          <div class="value">{{ DayCount[v.value] || 0 }}</div>
        </div>
        <div class="count-r" :style="{ background: v.colorP }">
          <img :src="v.icon" class="count-pic" alt="" />
        </div>
      </div>
    </div>

    <div>
      <div class="echarts">
        <div class="chart-title">渠道来源分析</div>
        <div class="type">
          <div class="type-item">
            <barEchart ename="total" :data="totalData"></barEchart>
            <p class="e-title">总用户</p>
          </div>
          <div class="type-item">
            <barEchart ename="yestday" :data="yestData"></barEchart>
            <p class="e-title">昨日新增用户</p>
          </div>
        </div>
      </div>

      <div class="flex">
        <div class="age-wrap">
          <div class="chart-title">年龄分布</div>
          <rowBarEchart ename="age" :data="ageData"></rowBarEchart>
        </div>
        <div class="sex-wrap">
          <div class="chart-title">性别分布</div>
          <div class="sex-item">
            <perEchart ename="totalsex" :data="totalsex"></perEchart>
            <p class="e-title">昨日新增用户</p>
          </div>
          <div class="sex-item">
            <perEchart ename="yestsex" :data="yestsex"></perEchart>
            <p class="e-title">昨日新增用户</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import barEchart from "../dataBoard/components/echarts/barEchart.vue";
import perEchart from "../dataBoard/components/echarts/perEchart.vue";
import rowBarEchart from "../dataBoard/components/echarts/rowBarEchart.vue";

export default {
  components: {
    barEchart,
    perEchart,
    rowBarEchart,
  },
  data() {
    return {
      nav: { firstNav: "用户管理中心", secondNav: "数据总览", url: "4_1" },
      is_active: false,
      newUser: 0,
      countList: [
        {
          name: "用户总数",
          value: "total",
          color: "#FCBF80",
          colorP: "#FDDABD",
          icon: require("@/assets/imgs/u1.png"),
        },
        {
          name: "昨日新增",
          value: "yestdayNew",
          color: "#FC93F1",
          colorP: "#FDC0F5",
          icon: require("@/assets/imgs/u2.png"),
        },
        {
          name: "昨日活跃",
          value: "yestdayLogin",
          color: "#A8AFFF",
          colorP: "#C9CFFE",
          icon: require("@/assets/imgs/u3.png"),
        },
        {
          name: "今日新增",
          value: "todayNew",
          color: "#77D97C",
          colorP: "#B4E7B4",
          icon: require("@/assets/imgs/u4.png"),
        },
        {
          name: "今日活跃",
          value: "todayLogin",
          color: "#FF7A7F",
          colorP: "#FFB6BE",
          icon: require("@/assets/imgs/u3.png"),
        },
      ],
      DayCount: {},
      totalData: { xData: [], yData: [] },
      yestData: { xData: [], yData: [] },

      totalsex: {},
      yestsex: {},

      ageData: { xData: [], yData: [] },
    };
  },
  computed: {},

  methods: {
    getUserCount() {
      let url = "/user/user/userCountDay2";
      this.$axios.get(url).then((res) => {
        this.DayCount = res.data.data;
      });
    },

    getUserSexCount() {
      let url = "/user/user/userSexCount";
      this.$axios.get(url).then((res) => {
        let t = [
          { name: "男", value: 0 },
          { name: "女", value: 0 },
        ];
        res.data.data.total.forEach((v) => {
          if (v.sex == 1) {
            t[0].value = v.count;
          }
          if (v.sex == 2) {
            t[1].value = v.count;
          }
        });
        this.totalsex = { Data: t };

        let y = [
          { name: "男", value: 0 },
          { name: "女", value: 0 },
        ];
        res.data.data.yest.forEach((v) => {
          if (v.sex == 1) {
            y[0].value = v.count;
          }
          if (v.sex == 2) {
            y[1].value = v.count;
          }
        });
        this.yestsex = { Data: y };
      });
    },

    getUserAge() {
      let url = "/user/userStudyCard/queryStudentAgeBackCount";
      this.$axios.get(url).then((res) => {
        let x = [];
        let y = [];
        let obj = res.data.data;
        for (let k of Object.keys(res.data.data)) {
          y.push(k);
          x.push(obj[k]);
        }
        this.ageData = {
          xData: x,
          yData: y,
        };
      });
    },
    getUserCenterData() {
      let url = "/user/user/queryRegisterNewCount";
      this.$axios.get(url, { params: {} }).then((res) => {
        this.totalData = {
          xData: res.data.data.name,
          yData: res.data.data.total,
        };
        this.yestData = {
          xData: res.data.data.name,
          yData: res.data.data.yestday,
        };
      });
    },
  },

  mounted() {
    this.getUserCount();
    this.getUserCenterData();
    this.getUserSexCount();
    this.getUserAge();
  },
};
</script>

<style lang="less">
.chart-title {
  font-family: "MicrosoftYaHeiLight";
  font-size: 1.5rem;
  margin-top: 20px;
}

.count-wrap {
  display: flex;
}
.count-item {
  width: 260px;
  height: 110px;
  box-sizing: border-box;

  border-radius: 14px;
  margin-right: 20px;
  padding: 25px 30px 0 30px;
  display: flex;
  justify-content: space-between;
  &:hover {
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  }
  .count-l {
  }
  .name {
    font-size: 1rem;
    color: #ffffff;
    margin-bottom: 4px;
  }
  .value {
    font-size: 1.3rem;
    color: #ffffff;
  }
  .count-r {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .count-pic {
    width: 40px;
    height: 40px;
  }
}

.type {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.type-item {
  width: 100%;
  height: 300px;
}
.e-title {
  text-align: center;
  font-size: 16px;
  color: #666;
}

.sex-wrap {
  width: 40%;
}
.sex-item {
  width: 80%;
  height: 200px;
}
.age-wrap{
  width: 55%;
  margin-right: 20px;
}
</style>