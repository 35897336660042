<template>
  <div :ref="ename" :style="{ width, height }"></div>
</template>
    
    <script>
export default {
  props: {
    data: {
      type: Object,
    },
    ename: {
      type: String,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    onclick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myChart: "",
      list: {},
      total: 0,
      option: {
        backgroundColor: "#ffffff",
        color: ["#F79646"],
        grid: {
          left: "8%",
          right: "12%",
          top: "10%",
          bottom: "0%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          formatter: function (params) {
            return params[0].name + " : " + params[0].value;
          },
        },
        xAxis: [
          {
            show: false,
          },
          {
            show: false,
          },
        ],
        yAxis: {
          type: "category",
          axisLabel: {
            show: true, //让Y轴数据不显示
            interval: 0,
            color: "#333",
            fontSize: 13,
            padding: [5, 5, 5, 5],
          },
          itemStyle: {},
          axisTick: {
            show: false, //隐藏Y轴刻度
          },
          axisLine: {
            show: false, //隐藏Y轴线段
          },

          data: [],
        },
        series: [
          {
            show: true,
            type: "bar",
            barGap: "-100%",
            barWidth: "20px", //统计条宽度
            max: 1,
            itemStyle: {
              normal: {
                barBorderRadius: 4,
              },
            },
            label: {
              normal: {
                show: true,
                textStyle: {
                  color: "#333", //百分比颜色
                },
                position: "right",
                //百分比格式
                formatter: function (data) {
                  let rs = 0;
                  return rs + "%";
                },
              },
            },
            labelLine: {
              show: false,
            },
            z: 2,
            data: [],
          },

          {
            show: true,
            type: "bar",
            barGap: "-100%",
            barWidth: "20px", //统计条宽度
            itemStyle: {
              normal: {
                barBorderRadius: 4,
                color: "rgba(0,0,0,0.2)",
              },
            },
            z: 1,
            data: [10, 10, 10],
          },
        ],
      },
    };
  },
  computed: {
    full() {
      return this.$store.state.menuShow;
    },
  },
  watch: {
    full(newval) {
      this.myChart.resize();
    },
    data: {
      handler(newVal) {
        if (newVal && this.myChart != "") {
          this.updateOption();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.getEchartData();
    window.addEventListener("resize", this.myChart.resize);
  },
  beforeDestroy() {
    // 移除窗口改变监听

    this.onclick && this.myChart.off("click", this.getClickInfo);
    window.removeEventListener("resize", this.myChart.resize);
  },
  methods: {
    getClickInfo(e) {
      console.log(e);
      this.$emit("click", e);
    },
    updateOption() {
      let total = this.data.xData.reduce((per, cur) => {
        return per + cur;
      }, 0);
      let max = [10, 10, 10];
      if (total > 0) {
        max = [total, total, total];
      }

      if (total > 0) {
        this.option.series[0].label.formatter = function (data) {
          let rs = 0;
          if (total > 0) {
            rs = ((data.value / total) * 100).toFixed(0);
          }
          return rs + "%";
        };
      }

      this.option.series[0].data = this.data.xData;
      this.option.series[1].data = max;
      this.option.yAxis.data = this.data.yData;
      this.myChart.setOption(this.option);
    },
    getEchartData() {
      let total = this.data.xData.reduce((per, cur) => {
        return per + cur;
      }, 0);
      let max = [10, 10, 10];
      if (total > 0) {
        max = [total, total, total];
      }

      if (total > 0) {
        this.option.series[0].label.formatter = function (data) {
          let rs = 0;
          if (total > 0) {
            rs = ((data.value / total) * 100).toFixed(0);
          }
          return rs + "%";
        };
      }

      this.option.series[0].data = this.data.xData;
      this.option.yAxis.data = this.data.yData;
      this.option.series[1].data = max;
      const chart = this.$refs[this.ename];
      if (chart) {
        this.myChart = this.$echarts.init(chart);
        this.myChart.setOption(this.option);
        this.onclick && this.myChart.on("click", this.getClickInfo);
      }
    },
  },
};
</script>
    
    <style lang="scss" scoped>
</style>