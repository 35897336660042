<template>
  <div :ref="ename" :style="{ width, height }"></div>
</template>
    
    <script>
import * as echarts from "echarts/core";
export default {
  props: {
    data: {
      type: Object,
    },
    ename: {
      type: String,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    onclick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let lineOption = {
      lineStyle: {
        color: "rgba(151,151,151,0.5)",
        type: "dashed",
      },
    };
    return {
      myChart: "",
      list: [],
      option: {
        backgroundColor: "#fff",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "14%",
          right: "3%",
          left: "5%",
          bottom: "14%",
        },
        xAxis: [
          {
            type: "category",
            name:'',
            data: [],
            axisLine: lineOption,
            axisTick: {
              show: false,
            },
            axisLabel: {
              margin: 10,
              color: "#666",
              textStyle: {
                fontSize: 14,
              },
            },
          },
        ],
        yAxis: [
          {
            name:'',
            axisLabel: {
              formatter: "{value}",
              color: "#666",
            },
            axisTick: {
              show: false,
            },
            axisLine: lineOption,
            splitLine: lineOption,
          },
          {
            axisLine: lineOption,
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: [],
            barWidth: "20px",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                    color: "#FFBA7F",
                    },
                    {
                      offset: 1,
                       color: "#FFE5B8",
                    },
                  ],
                  false
                ),
              },
            },
           label: {
            show: true,
            position: 'top',
            fontSize: 16
        },
          },
        ],
      },
    };
  },
  computed: {
    full() {
      return this.$store.state.menuShow;
    },
  },
  watch: {
    full(newval) {
      this.myChart.resize();
    },
    data: {
      handler(newVal) {
        if (newVal && this.myChart != "") {
          this.list = newVal;
          this.updateOption();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getEchartData();
    window.addEventListener("resize", this.myChart.resize);
  },
  beforeDestroy() {
    // 移除窗口改变监听

    this.onclick && this.myChart.off("click", this.getClickInfo);
    window.removeEventListener("resize", this.myChart.resize);
  },
  methods: {
    getClickInfo(e) {
      console.log(e);

      this.$emit("click", e);
    },
    updateOption() {
      this.option.xAxis[0].data = this.list.xData;
      this.option.series[0].data = this.list.yData;
      this.myChart.setOption(this.option);
    },
    getEchartData() {
      this.option.xAxis[0].data = this.list.xData;
      this.option.series[0].data = this.list.yData;
      const chart = this.$refs[this.ename];
      if (chart) {
        this.myChart = this.$echarts.init(chart);
        this.myChart.setOption(this.option);
        this.onclick && this.myChart.on("click", this.getClickInfo);
      }
    },
  },
};
</script>
    
    <style lang="scss" scoped>
</style>